import React from 'react';

// @material-ui/core components
import Box from "@material-ui/core/Box";
const imgServicePath = "/image/transfer.png";
function CuentasSM() {
  return (
    <Box>
        <img src={imgServicePath} width="auto" style={{maxHeight: 600}} alt="" />
    </Box>
);
}

export default CuentasSM;